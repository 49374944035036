import './advertise.scss';
import React from 'react';
import { Link } from 'react-router-dom';

function Advertise({ hanleClose }) {
	const handleCloseAdvertise = () => {
		hanleClose(false);
	};
	return (
		<div className='advertise'>
			<div className='advertise-close' onClick={handleCloseAdvertise}>
				<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M8 8.00003L25.0323 25.0323M8 25.0323L25.0323 8'
						strokeWidth='4'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</div>
			<h1>
				<span>Hãy sử dụng</span> BIBOO <span>để</span>
				<span> khám phá kho tàng sách điện tử</span>
			</h1>
			<Link to='https://biboo.com.vn/' target='_blank' className='btn-goto'>
				Truy cập
			</Link>
		</div>
	);
}

export default Advertise;
