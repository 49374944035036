import PropTypes from 'prop-types';
import { useLayoutEffect, useState } from 'react';
import BookThumbnail from 'shared/book-thumbnail';
import ReactRating from 'shared/react-rating';
import ReadMore from 'shared/read-more';
import './review-book-info.scss';

const ReviewBookInfo = ({ bookInfo }) => {
	const [viewLessHeight, setViewLessHeight] = useState(0);
	const [size, setSize] = useState('lg');

	useLayoutEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			if (width >= 768) {
				setSize('lg');
				const container = document.querySelector('.review-book-info');
				const bookHeight = container.querySelector('.book-thumbnail').offsetHeight;
				const takenHeight = document.querySelector('.review-book-info__content').firstChild.offsetHeight;
				setViewLessHeight(bookHeight - takenHeight);
			} else if (width >= 500) {
				setSize('md');
			} else {
				setSize('sm');
			}
		};

		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	});

	return (
		<>
			<div className='review-book-info'>
				<BookThumbnail
					className='review-book-info__image'
					size={size}
					source={bookInfo.frontBookCover || bookInfo.images[0]}
				/>
				<div className='review-book-info__content'>
					<div>
						<div className='review-book-info__name-author'>
							<h1 className='review-book-info__name'>{bookInfo.name}</h1>
							{!!bookInfo.authors.length && (
								<div className='review-book-info__author'>
									{Array.isArray(bookInfo.authors) && bookInfo.authors.length > 0 ? (
										<>
											Bởi{' '}
											{bookInfo.authors.map((author, index) => (
												<span key={index}>
													<span className='verified'>{author.authorName}</span>
													{index + 1 < bookInfo.authors.length && ', '}
												</span>
											))}
										</>
									) : (
										'Chưa cập nhật tác giả'
									)}
								</div>
							)}
						</div>

						<div className='review-book-info__stars'>
							{bookInfo.avgRating ? (
								<>
									<ReactRating readonly={true} initialRating={bookInfo.avgRating.toFixed(1)} />
									<span>(Trung bình {bookInfo.avgRating.toFixed(1)} sao)</span>
								</>
							) : (
								<div>Chưa có đánh giá</div>
							)}
						</div>
					</div>

					{size === 'lg' && (
						<div className='review-book-info__description'>
							<ReadMore text={bookInfo.description} viewLessHeight={viewLessHeight} />
						</div>
					)}
				</div>
			</div>
			{size !== 'lg' && (
				<div className='review-book-info__description'>
					<ReadMore text={bookInfo.description} viewLessHeight={150} />
				</div>
			)}
		</>
	);
};

ReviewBookInfo.propTypes = {
	bookInfo: PropTypes.object,
};

export default ReviewBookInfo;
