import { useState, useEffect, useCallback } from 'react';
import Button from 'shared/button';
import NormalContainer from 'components/layout/normal-container';
import SearchField from 'shared/search-field';
import './detail-friend.scss';
import { useLocation } from 'react-router-dom';
import FriendsItem from 'shared/friends';
import {
	getListFollowing,
	getListFollowrs,
	getListReqFriendsToMe,
	getRecommendFriend,
} from 'reducers/redux-utils/user';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationError } from 'helpers/Error';
import _ from 'lodash';
import { generateQuery } from 'helpers/Common';
import { getTopUser } from 'reducers/redux-utils/ranks';
import LoadingIndicator from 'shared/loading-indicator';
import InfiniteScroll from 'react-infinite-scroll-component';
import BackButton from 'shared/back-button';

const DetailFriend = () => {
	const location = useLocation();
	const { userInfo } = useSelector(state => state.auth);
	const [userName, setUserName] = useState('');
	const [listCount, setListCount] = useState(0);
	const [listFollowings, setListFollowings] = useState([]);
	const suggestions = location.pathname === '/friends/suggestions';
	const recommend = location.pathname === '/friends/recommend';
	const invitation = location.pathname === '/friends/invitation';
	const following = location.pathname === '/friends/following';
	const follower = location.pathname === '/friends/follower';
	const [inputSearch, setInputSearch] = useState('');
	const [filter, setFilter] = useState('[]');
	const [listFriendSuggest, setListFriendSuggest] = useState([]);
	const [listRecommendFriend, setListRecommendFriend] = useState([]);
	const [callApiStart, setCallApiStart] = useState(0);
	const [hasMore, setHasMore] = useState(true);

	const dispatch = useDispatch();

	const { isAuth } = useSelector(state => state.auth);
	const result = userInfo?.favoriteCategory?.map(item => item.categoryId);

	const updateInputSearch = value => {
		if (value) {
			const filterValue = [];
			filterValue.push({
				'operator': 'search',
				'value': value.toLowerCase().trim(),
				'property': 'firstName,lastName',
			});
			setFilter(JSON.stringify(filterValue));
		} else {
			setFilter('[]');
		}
	};

	const debounceSearch = useCallback(_.debounce(updateInputSearch, 0), []);

	const handleSearch = e => {
		setInputSearch(e.target.value);
		if (e.target.value === '') {
			debounceSearch('');
		}
	};

	const onClickSearchBtn = () => {
		debounceSearch(inputSearch);
	};

	const onBtnEnterPress = e => {
		if (e.key === 'Enter') {
			debounceSearch(inputSearch);
		}
	};

	const getSuggestFriendByTopFollow = async () => {
		const params = {
			reportType: 'topFollow',
			by: 'month',
		};
		try {
			if (isAuth) {
				const data = await dispatch(getTopUser(params)).unwrap();
				return data.rows;
			}
		} catch (err) {
			NotificationError(err);
		}
	};

	const getSuggestFriendByCategory = async () => {
		const params = {
			reportType: 'topRead',
			by: 'month',
			categoryId: result[0],
		};
		try {
			if (isAuth) {
				const data = await dispatch(getTopUser(params)).unwrap();
				return data.rows;
			}
		} catch (err) {
			NotificationError(err);
		}
	};

	const getRecommendFriendData = async () => {
		const params = {
			start: 0,
		};
		try {
			const data = await dispatch(getRecommendFriend(params)).unwrap();
			setListFollowings(data);
			setListRecommendFriend(data);
		} catch (err) {
			NotificationError(err);
		}
	};

	useEffect(async () => {
		const query = generateQuery(callApiStart, 10, filter);
		const userId = userInfo.id;

		try {
			if (!_.isEmpty(userInfo) && hasMore) {
				setUserName(userInfo.fullName || userInfo.firstName + ' ' + userInfo.lastName);
				if (following) {
					const response = await dispatch(getListFollowing({ userId, ...query })).unwrap();
					setListCount(response.count);
					setListFollowings(listFollowings.concat(response.rows));
				} else if (follower) {
					const response = await dispatch(getListFollowrs({ userId, ...query })).unwrap();
					setListCount(response.count);
					setListFollowings(listFollowings.concat(response.rows));
				} else if (invitation) {
					const response = await dispatch(getListReqFriendsToMe({ ...query })).unwrap();
					setListCount(response.count);
					setListFollowings(listFollowings.concat(response.rows));
				} else if (suggestions) {
					if (inputSearch.length > 0) {
						setListFollowings(
							listFriendSuggest.filter(item => {
								const fullName = item.firstName + ' ' + item.lastName;
								return fullName.toLowerCase().includes(inputSearch.toLowerCase());
							})
						);
					} else {
						Promise.all([getSuggestFriendByTopFollow(), getSuggestFriendByCategory()]).then(data => {
							const listUser = !_.isEmpty(data) && data.reduce((acc, item) => acc.concat(item));
							const listuserNew = listUser.filter(item => item.id !== userInfo.id);
							const newList = listuserNew.reduce((acc, curr) => {
								const userId = acc.find(item => item.id === curr.id);
								if (!userId) {
									return acc.concat([curr]);
								} else {
									return acc;
								}
							}, []);
							const newListNotFriend = newList.filter(item => item.relation !== 'friend');
							setListFriendSuggest(newListNotFriend);
							setListFollowings(newListNotFriend);
						});
					}
				} else if (getRecommendFriendData) {
					if (inputSearch.length > 0) {
						setListFollowings(
							listRecommendFriend.filter(item => {
								const fullName = item.firstName + ' ' + item.lastName;
								return fullName.toLowerCase().includes(inputSearch.toLowerCase());
							})
						);
					} else {
						getRecommendFriendData();
					}
				}
			}
		} catch (err) {
			NotificationError(err);
		}
	}, [userInfo, filter, callApiStart, hasMore]);

	const renderTitleHeader = () => {
		if (suggestions) {
			return 'Tất cả gợi ý từ BXH';
		} else if (recommend) {
			return 'Tất cả những người bạn có thể biết';
		} else if (invitation) {
			return 'Tất cả lời mời kết bạn';
		} else if (following) {
			return `Tất cả nguời ${userName} đang theo dõi`;
		} else if (follower) {
			return `Tất cả nguời đang theo dõi ${userName} `;
		}
	};

	const renderTitleContainer = () => {
		if (suggestions) {
			return 'Bạn bè gợi ý từ BXH';
		} else if (recommend) {
			return 'gợi ý kết bạn';
		} else if (invitation) {
			return 'Lời mời kết bạn';
		} else if (following) {
			return ` ${userName} đang theo dõi `;
		} else if (follower) {
			return `đang theo dõi ${userName}`;
		}
	};

	const renderCount = () => {
		if (suggestions || recommend) {
			return listFollowings.length;
		} else if (following || follower || invitation || recommend) {
			return listCount;
		}
	};

	const getMoreData = () => {
		if (listFollowings.length >= listCount) {
			setHasMore(false);
		} else {
			setCallApiStart(callApiStart + 1);
		}
	};

	const renderListMap = () => {
		return (
			listFollowings.length > 0 && (
				<InfiniteScroll
					dataLength={listFollowings.length}
					next={getMoreData}
					hasMore={hasMore}
					loader={<LoadingIndicator />}
					className='myfriends__layout__container'
				>
					{listFollowings.map(item => {
						if (!item.isDeleted) {
							return <FriendsItem key={item.id} data={item} listFriendReq={listFollowings} />;
						}
					})}
				</InfiniteScroll>
			)
		);
	};

	return (
		<NormalContainer>
			<div className='friends__container'>
				<div className='notification__main__container'>
					<BackButton destination='/friends' />
					<div className='notification__main__title'>{renderTitleHeader()}</div>
				</div>
				<div className='friends__detail__header'>
					<div className='friends__search'>
						<SearchField
							handleClickSearch={onClickSearchBtn}
							placeholder='Tìm kiếm bạn bè'
							handleChange={handleSearch}
							value={inputSearch}
							onKeyDown={onBtnEnterPress}
						/>
						<Button onClick={onClickSearchBtn} className='connect-button' isOutline={false} name='friend'>
							<span>Tìm kiếm</span>
						</Button>
					</div>
				</div>
				<div className='myfriends__container'>
					<div className='myfriends__container__content'>
						<div className='myfriends__title__addfriend'>
							{renderCount()} {renderTitleContainer()}
						</div>
					</div>
					<>{renderListMap()}</>
				</div>
			</div>
		</NormalContainer>
	);
};

export default DetailFriend;
