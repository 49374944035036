import PropTypes from 'prop-types';
import bookImage from 'assets/images/default-book.png';
import './book-thumbnail.scss';
import classNames from 'classnames';
import { useState } from 'react';

const BookThumbnail = ({ frontBookCover, images, source, name, size, handleClick, className, data }) => {
	const [clientXonMouseDown, setClientXonMouseDown] = useState();
	const [clientYonMouseDown, setClientYonMouseDown] = useState();

	const handleOnMouseDown = e => {
		setClientXonMouseDown(e.clientX);
		setClientYonMouseDown(e.clientY);
		e.preventDefault();
	};

	const onClick = e => {
		e.stopPropagation();
		if (clientXonMouseDown && (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY)) {
			e.preventDefault();
		} else {
			handleClick(data);
		}
	};
	return (
		<div
			className={classNames(`book-thumbnail book-thumbnail-${size}`, { [`${className}`]: className })}
			onMouseDown={handleOnMouseDown}
			onClick={onClick}
			title={name}
		>
			<img
				src={frontBookCover || images[0] || source || bookImage}
				alt={name}
				onError={e => e.target.setAttribute('src', `${bookImage}`)}
			/>
		</div>
	);
};

BookThumbnail.defaultProps = {
	images: [],
	source: '',
	name: '',
	size: 'md',
	className: '',
	data: {},
	handleClick: () => {},
};

BookThumbnail.propTypes = {
	source: PropTypes.string,
	images: PropTypes.array,
	name: PropTypes.string,
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
	handleClick: PropTypes.func,
	className: PropTypes.string,
	data: PropTypes.any,
	frontBookCover: PropTypes.string,
};

export default BookThumbnail;
