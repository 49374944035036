import PropTypes from 'prop-types';
import { useLayoutEffect, useState } from 'react';
import BookSlider from 'shared/book-slider';
import './category-group.scss';

const CategoryGroup = ({
	data,
	list,
	title,
	handleViewBookDetail,
	handleViewCategoryDetail,
	inCategory = false,
	inCategoryDetail = false,
	inResult = false,
}) => {
	const [size, setSize] = useState('lg');

	useLayoutEffect(() => {
		function handleResize() {
			const width = window.innerWidth;
			if (width >= 768) {
				setSize('lg');
			} else if (width >= 500) {
				setSize('md');
			} else {
				setSize('sm');
			}
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	return (
		<div className='category-group'>
			{list.length > 0 ? (
				<BookSlider
					className='category-group__slider'
					title={title}
					list={list}
					size={size}
					handleViewBookDetail={handleViewBookDetail}
					inCategory={inCategory}
					inCategoryDetail={inCategoryDetail}
					inResult={inResult}
				/>
			) : (
				<div>
					<p className='book-slider__title'>{title}</p>
					<p className='text-center mb-4'>Hệ thống chưa có sách theo chủ đề này.</p>
				</div>
			)}
			<button className='category-group__link' onClick={() => handleViewCategoryDetail(data)}>
				Xem tất cả
			</button>
		</div>
	);
};

CategoryGroup.propTypes = {
	data: PropTypes.object,
	list: PropTypes.array,
	title: PropTypes.string,
	handleViewBookDetail: PropTypes.func,
	handleViewCategoryDetail: PropTypes.func,
	inCategory: PropTypes.bool,
	inCategoryDetail: PropTypes.bool,
	inResult: PropTypes.bool,
};

export default CategoryGroup;
