import { useState } from 'react';
import './style.scss';

const InfoWisfeed = () => {
	const [left, setLeft] = useState(0);
	const [leftTwo, setLeftTwo] = useState(0);

	return (
		<div
			className='ladi-wraper'
			data-padding-top-id='footer'
			data-scroll-padding-top='85'
			style={{ paddingTop: '85px', maxWidth: '1440px', margin: 'auto' }}
		>
			{/* <div
				id='footer'
				className='ladi-section'
				data-top='0'
				data-left='0'
				data-sticky='true'
				style={{
					width: '100%',
					top: '0px',
					left: ' 0px',
					position: 'fixed',
					zIndex: '90000050',
				}}
			>
				<div className='ladi-section-background'></div>
				<div className='ladi-container'>
					<div id='IMAGE189' className='ladi-element'>
						<div className='ladi-image'>
							<div className='ladi-image-background'></div>
						</div>
					</div>
				</div>
			</div> */}
			<div id='SECTION2' className='ladi-section'>
				<div className='ladi-section-background'></div>
				<div className='ladi-container'>
					<div id='IMAGE3' className='ladi-element'>
						<div className='ladi-image'>
							<div className='ladi-image-background'></div>
						</div>
					</div>
					<div id='BOX181' className='ladi-element'>
						<div className='ladi-box'></div>
					</div>
					<div id='GROUP180' className='ladi-element'>
						<div className='ladi-group'>
							<div id='HEADLINE120' className='ladi-element ladi-animation'>
								<h1 className='ladi-headline'>LOADING... 70%</h1>
							</div>
							<div id='IMAGE178' className='ladi-element'>
								<div className='ladi-image'>
									<div className='ladi-image-background'></div>
								</div>
							</div>
							<div id='IMAGE179' className='ladi-element ladi-animation'>
								<div className='ladi-image'>
									<div className='ladi-image-background'></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id='SECTION122' className='ladi-section'>
				<div className='ladi-section-background'></div>
				<div className='ladi-container'>
					<div id='HEADLINE123' className='ladi-element'>
						<h1 className='ladi-headline'>Công ty cổ phần công nghệ và truyền thông Tecinus</h1>
					</div>
					{/* <div id='HEADLINE125' className='ladi-element'>
						<h3 className='ladi-headline'>
							Wisfeed: Wis (Wisdom): trí tuệ + Feed (Newsfeed): Nguồn cấp thông tin.&nbsp;
						</h3>
					</div> */}
					<div data-action='true' id='GROUP131' className='ladi-element'>
						<div className='ladi-group'>
							<div id='BOX129' className='ladi-element'>
								<div className='ladi-box'></div>
							</div>
							<div id='IMAGE126' className='ladi-element'>
								<div className='ladi-image'>
									<div className='ladi-image-background'></div>
								</div>
							</div>
						</div>
					</div>
					<div id='HEADLINE128' className='ladi-element'>
						<h3 className='ladi-headline ladi-lazyload'>
							Chúng tôi là Startup công nghệ, mạng xã hội đầu tiên của người Việt Nam giúp những người
							sáng tạo nội dung và người dùng có thể kết nối với nhau một cách đơn giản, dễ dàng nhất
							thông qua việc đọc, chia sẻ tri thức, trí tuệ bằng trải nghiệm công nghệ tiên phong. Wisfeed
							thể hiện rằng, chúng tôi định vị mình là một mạng xã hội về tri thức, là nơi để mỗi người
							được đọc, học hỏi, chia sẻ và khẳng định bản thân bằng trí tuệ.
						</h3>
					</div>
					<div id='HEADLINE182' className='ladi-element'>
						<h3 className='ladi-headline'>
							<span style={{ backgroundColor: 'rgb(232, 210, 180)' }}>
								Sứ mệnh của chúng tôi là:Khẳng định bản thân bằng trí tuệ.
							</span>
						</h3>
					</div>
				</div>
			</div>
			<div id='SECTION7' className='ladi-section'>
				<div className='ladi-section-background ladi-lazyload'></div>
				<div className='ladi-container'>
					<div id='HEADLINE9' className='ladi-element ladi-animation-hidden'>
						<h3 className='ladi-headline ladi-lazyload' style={{ display: 'flex', gap: '10px' }}>
							<div style={{ color: 'rgb(225, 177, 128)' }}>►&nbsp;</div> Trở thành mạng xã hội hàng đầu
							thế giới dành cho những người sáng tạo nội dung, chia sẻ tri thức và khẳng định bản thân
							bằng trí tuệ.
						</h3>
						<br />
						<br />
						<h3 className='ladi-headline ladi-lazyload' style={{ display: 'flex', gap: '10px' }}>
							<div style={{ color: 'rgb(225, 177, 128)' }}>►&nbsp;</div>
							Trở thành niềm tự hào của Trí tuệ Việt Nam, Startup kì lân, được định giá tỷ đô.
						</h3>
					</div>
					<div id='CAROUSEL10' className='ladi-element' data-scrolled='false'>
						<div className='ladi-carousel'>
							<div className='ladi-carousel-content' style={{ left: `${left}px` }}>
								<div data-action='true' id='IMAGE18' className='ladi-element'>
									<div className='ladi-image'>
										<div className='ladi-image-background ladi-lazyload'></div>
									</div>
								</div>
								<div data-action='true' id='IMAGE16' className='ladi-element'>
									<div className='ladi-image'>
										<div className='ladi-image-background ladi-lazyload'></div>
									</div>
								</div>
								<div data-action='true' id='IMAGE19' className='ladi-element'>
									<div className='ladi-image'>
										<div className='ladi-image-background ladi-lazyload'></div>
									</div>
								</div>
							</div>

							<div
								className={`ladi-carousel-arrow ladi-carousel-arrow-left ${left === 0 && ' opacity-0'}`}
								onClick={() => {
									const numberLeft = left;
									if (numberLeft === -480) setLeft(0);
									if (numberLeft === -960) setLeft(-480);
								}}
							></div>
							<div
								className='ladi-carousel-arrow ladi-carousel-arrow-right'
								onClick={() => {
									const numberLeft = left;
									if (numberLeft === 0) return setLeft(-480);
									if (numberLeft === -480) return setLeft(-960);
								}}
							></div>
						</div>
					</div>
					<div id='GROUP133' className='ladi-element'>
						<div className='ladi-group'>
							<div id='HEADLINE8' className='ladi-element'>
								<h1 className='ladi-headline ladi-lazyload'>Tầm nhìn của Tecinus</h1>
							</div>
							<div id='LINE132' className='ladi-element'>
								<div className='ladi-line'>
									<div className='ladi-line-container'></div>
								</div>
							</div>
						</div>
					</div>
					<div id='HEADLINE183' className='ladi-element ladi-animation-hidden'>
						<h3 className='ladi-headline ladi-lazyload'></h3>
					</div>
					<div id='HEADLINE184' className='ladi-element ladi-animation-hidden'>
						<h3 className='ladi-headline ladi-lazyload'></h3>
					</div>
				</div>
			</div>
			<div id='SECTION134' className='ladi-section'>
				<div className='ladi-section-background ladi-lazyload'></div>
				<div className='ladi-container'>
					<div id='LINE185' className='ladi-element'>
						<div className='ladi-line'>
							<div className='ladi-line-container'></div>
						</div>
					</div>
					<div id='LINE186' className='ladi-element'>
						<div className='ladi-line'>
							<div className='ladi-line-container'></div>
						</div>
					</div>
					<div id='HEADLINE136' className='ladi-element'>
						<h1 className='ladi-headline ladi-lazyload'>Giá trị cốt lõi của Wisfeed</h1>
					</div>
					<div id='GROUP141' className='ladi-element'>
						<div className='ladi-group'>
							<div id='HEADLINE139' className='ladi-element'>
								<h2 className='ladi-headline ladi-lazyload'>
									<span style={{ backgroundColor: 'rgb(46, 45, 66)' }}>Khẳng định bản thân</span>
								</h2>
							</div>
							<div id='HEADLINE140' className='ladi-element'>
								<h3 className='ladi-headline ladi-lazyload'>
									Bạn là đại diện cho trí tuệ của bạn.Wisfeed giúp bạn để lại dấu ấn và tinh hoa cho
									đời bằng những gì bạn học hỏi và chia sẻ cho cộng đồng.
								</h3>
							</div>
						</div>
					</div>
					<div id='GROUP142' className='ladi-element'>
						<div className='ladi-group'>
							<div id='HEADLINE143' className='ladi-element'>
								<h2 className='ladi-headline ladi-lazyload'>
									<span style={{ backgroundColor: 'rgb(225, 177, 128)' }}>CHIA SẺ</span>
								</h2>
							</div>
							<div id='HEADLINE144' className='ladi-element'>
								<h3 className='ladi-headline ladi-lazyload'>
									Giá trị của bạn được đo bằng những điều mà bạn chia sẻ trong cuộc đời và cho xã hội.
									Chúng tôi sẽ giúp những chia sẻ của bạn trở nên có ích với nhiều người hơn.
								</h3>
							</div>
						</div>
					</div>
					<div id='GROUP145' className='ladi-element'>
						<div className='ladi-group'>
							<div id='HEADLINE146' className='ladi-element'>
								<h2 className='ladi-headline ladi-lazyload'>
									<span style={{ backgroundColor: 'rgb(46, 45, 66)' }}>kết nối</span>
								</h2>
							</div>
							<div id='HEADLINE147' className='ladi-element'>
								<h3 className='ladi-headline ladi-lazyload'>
									Kết nối trí tuệ để khiến chúng ta có thể cùng học hỏi, cùng phát triển và cùng thành
									công. Chúng tôi cầu thị sự học hỏi và kết nối không ngừng.
								</h3>
							</div>
						</div>
					</div>
					<div id='GROUP148' className='ladi-element'>
						<div className='ladi-group'>
							<div id='HEADLINE149' className='ladi-element'>
								<h2 className='ladi-headline ladi-lazyload'>
									<span style={{ backgroundColor: 'rgb(46, 45, 66)' }}>ĐƠN GIẢN - DỄ DÀNG</span>
								</h2>
							</div>
							<div id='HEADLINE150' className='ladi-element'>
								<h3 className='ladi-headline ladi-lazyload'>
									Đơn giản là đỉnh cao của trải nghiệm người dùng. Chúng tôi mong muốn giúp người dùng
									đạt mục tiêu tốt nhất bằng trải nghiệm đơn giản nhất.
								</h3>
							</div>
						</div>
					</div>
					<div id='GROUP151' className='ladi-element'>
						<div className='ladi-group'>
							<div id='HEADLINE152' className='ladi-element'>
								<h2 className='ladi-headline ladi-lazyload'>
									<span style={{ backgroundColor: 'rgb(225, 177, 128)' }}>CÔNG NGHỆ TIÊN PHONG</span>
								</h2>
							</div>
							<div id='HEADLINE153' className='ladi-element'>
								<h3 className='ladi-headline ladi-lazyload'>
									Chúng tôi là một Startup công nghệ và chúng tôi tự tin mang đến trải nghiệm công
									nghệ tiên phong của người Việt cho người dùng.
								</h3>
							</div>
						</div>
					</div>
					<div id='GROUP154' className='ladi-element'>
						<div className='ladi-group'>
							<div id='HEADLINE155' className='ladi-element'>
								<h2 className='ladi-headline ladi-lazyload'>
									<span style={{ backgroundColor: 'rgb(46, 45, 66)' }}>TRÁCH NHIỆM XÃ HỘI</span>
								</h2>
							</div>
							<div id='HEADLINE156' className='ladi-element'>
								<h3 className='ladi-headline ladi-lazyload'>
									Chúng tôi là những người trẻ đại diện cho trí tuệ của Việt Nam. Chúng tôi hoạt động
									vì trí tuệ xã hội thông thái và vì sự lan tỏa tri thức.
								</h3>
							</div>
						</div>
					</div>
					<div id='CAROUSEL163' className='ladi-element' data-scrolled='false'>
						<div className='ladi-carousel'>
							<div className='ladi-carousel-content' style={{ left: `${leftTwo}px` }}>
								<div data-action='true' id='IMAGE169' className='ladi-element'>
									<div className='ladi-image'>
										<div className='ladi-image-background ladi-lazyload'></div>
									</div>
								</div>
								<div data-action='true' id='IMAGE170' className='ladi-element'>
									<div className='ladi-image'>
										<div className='ladi-image-background ladi-lazyload'></div>
									</div>
								</div>
								<div data-action='true' id='IMAGE171' className='ladi-element'>
									<div className='ladi-image'>
										<div className='ladi-image-background ladi-lazyload'></div>
									</div>
								</div>
								<div data-action='true' id='IMAGE172' className='ladi-element'>
									<div className='ladi-image'>
										<div className='ladi-image-background ladi-lazyload'></div>
									</div>
								</div>
								<div data-action='true' id='IMAGE173' className='ladi-element'>
									<div className='ladi-image'>
										<div className='ladi-image-background ladi-lazyload'></div>
									</div>
								</div>
								<div data-action='true' id='IMAGE174' className='ladi-element'>
									<div className='ladi-image'>
										<div className='ladi-image-background ladi-lazyload'></div>
									</div>
								</div>
								<div data-action='true' id='IMAGE175' className='ladi-element'>
									<div className='ladi-image'>
										<div className='ladi-image-background ladi-lazyload'></div>
									</div>
								</div>
							</div>
							<div
								className={`ladi-carousel-arrow ladi-carousel-arrow-left ${
									leftTwo === 0 && ' opacity-0'
								}`}
								onClick={() => {
									const numberLeft = leftTwo;
									if (numberLeft === 2280) return setLeftTwo(-1900);
									if (numberLeft === -1900) return setLeftTwo(-1520);
									if (numberLeft === -1520) return setLeftTwo(-1140);
									if (numberLeft === -1140) return setLeftTwo(-760);
									if (numberLeft === -760) return setLeftTwo(-380);
									if (numberLeft === -380) return setLeftTwo(-0);
								}}
							></div>
							<div
								className='ladi-carousel-arrow ladi-carousel-arrow-right'
								onClick={() => {
									const numberLeft = leftTwo;
									if (numberLeft === 0) return setLeftTwo(-380);
									if (numberLeft === -380) return setLeftTwo(-760);
									if (numberLeft === -760) return setLeftTwo(-1140);
									if (numberLeft === -1140) return setLeftTwo(-1520);
									if (numberLeft === -1520) return setLeftTwo(-1900);
									if (numberLeft === -1900) return setLeftTwo(-2280);
								}}
							></div>
						</div>
					</div>
				</div>
			</div>
			<div id='SECTION92' className='ladi-section'>
				<div className='ladi-section-background ladi-lazyload'></div>
				<div className='ladi-container'>
					<div id='LINE187' className='ladi-element'>
						<div className='ladi-line'>
							<div className='ladi-line-container'></div>
						</div>
					</div>
					<div id='LINE188' className='ladi-element'>
						<div className='ladi-line'>
							<div className='ladi-line-container'></div>
						</div>
					</div>
					<div id='HEADLINE93' className='ladi-element'>
						<h1 className='ladi-headline ladi-lazyload'>Văn hóa con người Wisfeed</h1>
					</div>
					<div id='GROUP100' className='ladi-element'>
						<div className='ladi-group'>
							<div id='IMAGE95' className='ladi-element'>
								<div className='ladi-image'>
									<div className='ladi-image-background ladi-lazyload'></div>
								</div>
							</div>
							<div id='HEADLINE98' className='ladi-element'>
								<h2 className='ladi-headline ladi-lazyload'>
									<span style={{ backgroundColor: 'rgb(46, 45, 66)' }}>HAM HỌC HỎI</span>
								</h2>
							</div>
							<div id='HEADLINE99' className='ladi-element'>
								<h3 className='ladi-headline ladi-lazyload'>
									Chúng tôi yêu việc đọc, chia sẻ và phát triển bản thân. Chúng tôi làm việc với niềm
									đam "mê này để mỗi ngày đi làm chúng tôi đều tràn đầy năng lượng. Làm công việc mình
									yêu thích thì cả đời sẽ không phải làm việc ngày nào.
								</h3>
							</div>
						</div>
					</div>
					<div id='GROUP105' className='ladi-element'>
						<div className='ladi-group'>
							<div id='IMAGE106' className='ladi-element'>
								<div className='ladi-image'>
									<div className='ladi-image-background ladi-lazyload'></div>
								</div>
							</div>
							<div id='HEADLINE107' className='ladi-element'>
								<h2 className='ladi-headline ladi-lazyload'>
									<span style={{ backgroundColor: 'rgb(225, 177, 128)' }}>trung thực</span>
								</h2>
							</div>
							<div id='HEADLINE108' className='ladi-element'>
								<h3 className='ladi-headline ladi-lazyload'>
									Trung thực với công ty, trung thực với đồng nghiệp, trung thực trong công việc Chỉ
									khi tất cả chúng ta tin tưởng nhau, chúng ta mới có thể trở thành một khối thống
									nhất và luôn tiến về phía trước.
								</h3>
							</div>
						</div>
					</div>
					<div id='GROUP109' className='ladi-element'>
						<div className='ladi-group'>
							<div id='IMAGE110' className='ladi-element'>
								<div className='ladi-image'>
									<div className='ladi-image-background ladi-lazyload'></div>
								</div>
							</div>
							<div id='HEADLINE111' className='ladi-element'>
								<h2 className='ladi-headline ladi-lazyload'>
									<span style={{ backgroundColor: 'rgb(46, 45, 66)' }}>minh bạch</span>
								</h2>
							</div>
							<div id='HEADLINE112' className='ladi-element'>
								<h3 className='ladi-headline ladi-lazyload'>
									Chúng tôi đều biết rằng, những người xung quanh tôi đang làm gì và mục tiêu của họ
									là gì. Chúng tôi đều có chung một mục tiêu, sứ mệnh, tầm nhìn và chúng tôi muốn cùng
									nhau đi đến cái đích đó. Chỉ khi tất cả chúng tôi thành công thì mỗi người trong số
									chúng tôi đều trở nên giàu có.
								</h3>
							</div>
						</div>
					</div>
					<div id='GROUP113' className='ladi-element'>
						<div className='ladi-group'>
							<div id='IMAGE114' className='ladi-element'>
								<div className='ladi-image'>
									<div className='ladi-image-background ladi-lazyload'></div>
								</div>
							</div>
							<div id='HEADLINE115' className='ladi-element'>
								<h2 className='ladi-headline ladi-lazyload'>
									<span style={{ backgroundColor: 'rgb(225, 177, 128)' }}>sáng tạo</span>
								</h2>
							</div>
							<div id='HEADLINE116' className='ladi-element'>
								<h3 className='ladi-headline ladi-lazyload'>
									Luôn sáng tạo để đạt được mục tiêu nhanh nhất với nguồn lực và chi phí ít nhất.
									Chúng tôi khao khát những ý tưởng vì công ty và tôn trọng sự cống hiến của mỗi cá
									nhân, Dám thử, dám làm, dám startup và dám thành công.
								</h3>
							</div>
						</div>
					</div>
					<div id='HEADLINE215' className='ladi-element'>
						<h3 className='ladi-headline ladi-lazyload'>
							Chúng tôi là những người trẻ, nhỏ bé, bình thường nhưng muốn làm ra một thành công phi
							thường để làm rạng danh trí tuệ VIệt Nam trên thế giới. Chúng tôi biết rằng startup là luôn
							luôn có những khó khăn, có thể thất bại lên đến 90%, nhưng chúng tôi tin tưởng rằng, nếu
							chúng tôi thành công, chúng tôi sẽ trở thành niềm tự hào của Việt Nam và vươn tầm thế giới,
							trở thành doanh nghiệp hàng tỉ đô. Còn nếu chúng tôi thất bại, thì đó cũng là niềm vui vì
							chúng tôi được làm việc cùng nhau, được cùng vượt qua những khó khăn và được dám làm những
							điều mà mình mơ ước.
						</h3>
					</div>
					<div id='GROUP221' className='ladi-element'>
						<div className='ladi-group'>
							<div id='BOX220' className='ladi-element'>
								<div className='ladi-box ladi-lazyload'></div>
							</div>
							<div data-action='true' id='IMAGE216' className='ladi-element'>
								<div className='ladi-image'>
									<div className='ladi-image-background ladi-lazyload'></div>
								</div>
							</div>
						</div>
					</div>
					<div id='HEADLINE222' className='ladi-element'>
						<h3 className='ladi-headline ladi-lazyload'>
							<span style={{ backgroundColor: ' rgb(225, 177, 128)' }}>
								Chúng tôi là một startup với văn hóa con người là:
							</span>
						</h3>
					</div>
				</div>
			</div>
			<div id='SECTION21' className='ladi-section'>
				<div className='ladi-section-background ladi-lazyload'></div>
				<div className='ladi-container'>
					<div id='HEADLINE22' className='ladi-element'>
						<h1 className='ladi-headline ladi-lazyload'>
							Công ty cổ phần công nghệ và truyền thông Tecinus
						</h1>
					</div>
					<div id='HEADLINE23' className='ladi-element'>
						<h3 className='ladi-headline ladi-lazyload'>
							Địa chỉ:{' '}
							<span style={{ fontWeight: 'bold' }}>
								Ô số 34E, Khu đấu giá 3Ha, Phường Phúc Diễn, Bắc Từ Liêm, Hà Nội.
							</span>
							{/* Mã số thuế: <span style={{ fontWeight: 'bold' }}>0109795516</span> */}
						</h3>
					</div>
					<div id='HEADLINE25' className='ladi-element'>
						<h1 className='ladi-headline ladi-lazyload'>Người đại diện</h1>
					</div>
					<div id='HEADLINE26' className='ladi-element'>
						<h3 className='ladi-headline ladi-lazyload'>CEO - Founder: Nguyễn Văn Thắng</h3>
					</div>
					<div id='HEADLINE27' className='ladi-element'>
						<h3 className='ladi-headline ladi-lazyload'>
							SĐT: <span style={{ fontWeight: 'bold' }}>0978577713</span>
							<br />
							Email: <span style={{ fontWeight: 'bold' }}> thangnv@tecinus.vn</span>
						</h3>
					</div>
					<div id='HEADLINE28' className='ladi-element'>
						<h1 className='ladi-headline ladi-lazyload'>
							Nếu bạn có bất kì thông tin gì cần trao đổi (Đầu tư, Tuyển dụng, Đăng kí thành viên, Hợp
							tác, Góp ý), hãy để lại lời nhắn cho chúng tôi!
						</h1>
					</div>
					<div id='FORM29' data-config-id='62cf6fe58329d2008da49f0d' className='ladi-element'>
						<form autoComplete='off' method='post' className='ladi-form'>
							<div id='BUTTON30' className='ladi-element'>
								<div className='ladi-button'>
									<div className='ladi-button-background ladi-lazyload'></div>
									<div id='BUTTON_TEXT30' className='ladi-element'>
										<p className='ladi-headline ladi-lazyload'>GỬI TIN NHẮN</p>
									</div>
								</div>
							</div>
							<div id='FORM_ITEM31' className='ladi-element'>
								<div className='ladi-form-item-container'>
									<div className='ladi-form-item-background ladi-lazyload'></div>
									<div className='ladi-form-item'>
										<input
											autoComplete='off'
											tabIndex={1}
											name='name'
											required
											className='ladi-form-control'
											type='text'
											placeholder='Họ và tên*'
											value=''
										/>
									</div>
								</div>
							</div>
							<div id='FORM_ITEM32' className='ladi-element'>
								<div className='ladi-form-item-container'>
									<div className='ladi-form-item-background ladi-lazyload'></div>
									<div className='ladi-form-item'>
										<input
											autoComplete='off'
											tabIndex={2}
											name='email'
											required
											className='ladi-form-control'
											type='email'
											placeholder='Email*'
											value=''
										/>
									</div>
								</div>
							</div>
							<div id='FORM_ITEM33' className='ladi-element'>
								<div className='ladi-form-item-container'>
									<div className='ladi-form-item-background ladi-lazyload'></div>
									<div className='ladi-form-item'>
										<input
											autoComplete='off'
											tabIndex={3}
											name='phone'
											required
											className='ladi-form-control'
											type='tel'
											placeholder='Số điện thoại*'
											pattern='(\+84|0)(9|8|7|5|3)[0-9]{8}'
											value=''
										/>
									</div>
								</div>
							</div>
							<div id='FORM_ITEM34' className='ladi-element'>
								<div className='ladi-form-item-container'>
									<div className='ladi-form-item-background ladi-lazyload'></div>
									<div className='ladi-form-item'>
										<textarea
											autoComplete='off'
											tabIndex={4}
											name='message'
											required
											className='ladi-form-control'
											placeholder='Để lại lời nhắn cho chúng tôi*'
										></textarea>
									</div>
								</div>
							</div>
							<button type='submit' className='ladi-hidden'></button>
						</form>
					</div>
				</div>
			</div>
			<div id='SECTION_POPUP' className='ladi-section'>
				<div className='ladi-section-background ladi-lazyload'></div>
				<div className='ladi-container'>
					<div id='POPUP223' className='ladi-element'>
						<div className='ladi-popup'>
							<div className='ladi-popup-background'></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default InfoWisfeed;
