import '../forget-password.scss';
import ImgForget from 'assets/images/quen-mk 1.png';
import CreateNewPasswordAdminForm from './CreateNewPassword';
import { useNavigate } from 'react-router-dom';
import Storage from 'helpers/Storage';
import { useEffect } from 'react';
import LoginHeader from 'shared/login-header/LoginHeader';

function AdminCreateNewPassword() {
	const navigate = useNavigate();

	useEffect(() => {
		if (Storage.getAccessToken()) {
			navigate('/');
		}
	}, []);

	return (
		<div className='forget__container'>
			<LoginHeader to='/login' />
			<div className='forget__body'>
				<div className='forget__form__img'>
					<img src={ImgForget} alt='img' />
				</div>
				<CreateNewPasswordAdminForm />
			</div>
		</div>
	);
}

export default AdminCreateNewPassword;
