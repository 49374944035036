import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './highlightPosts.scss';

export default memo(function HighlightPosts() {
	const { highlightPostReduxData } = useSelector(state => state.hightlightPostsReducer);

	const [highlightPosts, setHighlightPosts] = useState([]);

	useEffect(() => {
		setHighlightPosts(highlightPostReduxData);
	}, [highlightPostReduxData]);

	const directNewUrl = () => {
		window.open('https://www.facebook.com/groups/nghiendocvaviet');
	};

	return (
		<>
			{!!highlightPosts.length && (
				<div className='highlight__post'>
					<h4>Bài viết nổi bật</h4>
					<div className='card card-link'>
						<ul className='card-link__list'>
							{highlightPosts.map((item, index) => (
								<a
									key={index}
									className='card-link__item'
									href={item.link}
									target='_blank'
									rel='noreferrer'
									title={item.title}
								>
									{item.title}
								</a>
							))}
						</ul>
					</div>
					<button className='sidebar__view-more-btn--blue' onClick={directNewUrl}>
						Xem thêm
					</button>
				</div>
			)}
		</>
	);
});
