import Slider from 'react-slick';
import SlideSettings from './wants-to-read-slide-settings';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import bookImage from 'assets/images/default-book.png';
import RouteLink from 'helpers/RouteLink';
import { useState } from 'react';

function TheBooksWantsToRead({ list }) {
	const [clientXonMouseDown, setClientXonMouseDown] = useState();
	const [clientYonMouseDown, setClientYonMouseDown] = useState();

	const navigate = useNavigate();

	const handleOnMouseDown = e => {
		setClientXonMouseDown(e.clientX);
		setClientYonMouseDown(e.clientY);
		e.preventDefault();
	};

	const handleNavigateToBookDetail = (e, item) => {
		e.stopPropagation();
		if (clientXonMouseDown && (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY)) {
			e.preventDefault();
		} else {
			navigate(RouteLink.bookDetail(item.id, item.name));
		}
	};

	return (
		list.length > 0 && (
			<div className='sidebar__block'>
				<h4 className='sidebar__block__title'>Sách muốn đọc</h4>
				<div className='sidebar__block__content'>
					{list.length > 2 ? (
						<Slider {...SlideSettings}>
							{list.map((item, index) => (
								<div
									key={index}
									className='wants-to-read__thumbnail'
									onMouseDown={handleOnMouseDown}
									onClick={e => handleNavigateToBookDetail(e, item)}
								>
									<img
										src={item?.frontBookCover || item?.images[0]}
										alt='image'
										onError={e => e.target.setAttribute('src', `${bookImage}`)}
									/>
								</div>
							))}
						</Slider>
					) : (
						<Row>
							{list.map((item, index) => (
								<Col lg={6} md={12} key={index} className='gy-2'>
									<div
										className='wants-to-read__thumbnail'
										onClick={e => handleNavigateToBookDetail(e, item)}
									>
										<img
											src={item?.frontBookCover || item?.images[0]}
											onError={e => e.target.setAttribute('src', `${bookImage}`)}
											alt='image'
										/>
									</div>
								</Col>
							))}
						</Row>
					)}
				</div>
			</div>
		)
	);
}

export default TheBooksWantsToRead;

TheBooksWantsToRead.propTypes = {
	list: PropTypes.array,
};
