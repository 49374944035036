import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './read-more.scss';
import { useRef } from 'react';

const ReadMore = ({ text, viewLessHeight }) => {
	const [hasMore, setHasMore] = useState(false);
	const [isShort, setIsShort] = useState(true);
	const [style, setStyle] = useState({});
	const postContentRef = useRef(null);
	const buttonSeeMore = useRef(null);

	useEffect(() => {
		setIsShort(true);
		setStyle({});

		if (postContentRef.current && viewLessHeight > 0) {
			// check text clamped when use webkit-box
			if (postContentRef.current.scrollHeight > viewLessHeight) {
				setHasMore(true);
			} else {
				setHasMore(false);
			}
		}

		if (hasMore) {
			// Chiều cao của phần chứa text sẽ là viewLessHeight trừ đi chiều cao của nút Xem thêm
			const buttonHeight = buttonSeeMore.current?.offsetHeight || 0;

			// Lấy line height
			const lineHeight = parseFloat(window.getComputedStyle(postContentRef.current).lineHeight);

			// Lấy số dòng để hiển thị
			const textHight = viewLessHeight - buttonHeight;
			const webkitLineClamp = ~~(textHight / lineHeight);

			setStyle({
				WebkitLineClamp: webkitLineClamp,
				maxHeight: `${webkitLineClamp * lineHeight}px`,
			});
		}
	}, [text, viewLessHeight, hasMore]);

	const generateContent = content => {
		const newContent = content.replace(/(<p><br><\/p>)+/g, '');
		return newContent;
	};

	return (
		<div className='read-more'>
			<div
				ref={postContentRef}
				className='post__content'
				dangerouslySetInnerHTML={{
					__html: generateContent(text),
				}}
				style={isShort ? style : {}}
			></div>
			{hasMore && (
				<div className='read-more-post' onClick={() => setIsShort(prev => !prev)} ref={buttonSeeMore}>
					{isShort ? 'Xem thêm' : 'Rút gọn'}
				</div>
			)}
		</div>
	);
};

ReadMore.defaultProp = {
	viewLessHeight: 0,
};

ReadMore.propTypes = {
	text: PropTypes.string.isRequired,
	viewLessHeight: PropTypes.number,
};

export default ReadMore;
