import { NotificationError } from 'helpers/Error';
import moment from 'moment';
import 'moment/locale/vi';
import BookIntro from 'pages/book-detail/book-intro';
import BookReview from 'pages/book-detail/book-review';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRatingBook } from 'reducers/redux-utils/book';
import ReviewRating from 'shared/review-rating';
import './book-info.scss';
moment.locale('vi');

const BookInfo = ({ bookInfo }) => {
	const dispatch = useDispatch();
	const [listRatingStar, setListRatingStar] = useState({});
	const [listRating, setListRating] = useState([]);
	const [topBookRank, setTopBookRank] = useState(-1);

	const { refreshRatingData } = useSelector(state => state.book);
	const { topBooksByWeek } = useSelector(state => state.ranks);

	const { bookId } = useParams();

	useEffect(() => {
		fetchData();
	}, [bookInfo, refreshRatingData]);

	useEffect(() => {
		if (topBooksByWeek && topBooksByWeek.length) {
			const index = topBooksByWeek.findIndex(item => item == bookId);
			setTopBookRank(index + 1);
		}
	}, [topBooksByWeek, bookId]);

	const fetchData = async () => {
		try {
			const res = await dispatch(getRatingBook(bookInfo?.id)).unwrap();
			setListRatingStar(res.data);
			const ratingList = [
				{
					level: 5,
					percent:
						(res.data.rate_5_star / res.data.count) * 100
							? (res.data.rate_5_star / res.data.count).toFixed(2) * 100
							: 0,
					total: res.data.rate_5_star,
				},
				{
					level: 4,
					percent:
						(res.data.rate_4_star / res.data.count) * 100
							? (res.data.rate_4_star / res.data.count).toFixed(2) * 100
							: 0,
					total: res.data.rate_4_star,
				},
				{
					level: 3,
					percent:
						(res.data.rate_3_star / res.data.count) * 100
							? (res.data.rate_3_star / res.data.count).toFixed(2) * 100
							: 0,
					total: res.data.rate_3_star,
				},
				{
					level: 2,
					percent:
						(res.data.rate_2_star / res.data.count) * 100
							? (res.data.rate_2_star / res.data.count).toFixed(2) * 100
							: 0,
					total: res.data.rate_2_star,
				},
				{
					level: 1,
					percent:
						(res.data.rate_1_star / res.data.count) * 100
							? (res.data.rate_1_star / res.data.count).toFixed(2) * 100
							: 0,
					total: res.data.rate_1_star,
				},
			];
			setListRating(ratingList);
		} catch (err) {
			NotificationError(err);
		}
	};

	const renderPublisherDate = dateData => {
		if (dateData) {
			return moment(dateData).format('DD/MM/YYYY');
		} else {
			return 'Chưa có dữ liệu';
		}
	};

	const infoArray = [
		{
			label: 'NXB / Công ty sách',
			info: bookInfo?.publisher?.name,
		},
		{
			label: 'Số trang',
			info: bookInfo?.page,
		},
		{
			label: 'Khổ sách',
			info: bookInfo?.paperSize,
		},
		{
			label: 'ISBN',
			info: bookInfo?.isbn,
		},
		{
			label: 'Ngày xuất bản',
			info: renderPublisherDate(bookInfo?.publishDate),
		},
	];

	return (
		<div className='book-info'>
			<BookIntro bookInfo={bookInfo} listRatingStar={listRatingStar} topBookRank={topBookRank} />
			<ReviewRating
				className='book-info__rating'
				list={listRating}
				ratingLevel={listRatingStar?.avg}
				ratingTotal={listRatingStar?.count}
			/>
			<div className='book-info__general-information'>
				<h2>Thông tin chi tiết</h2>

				{infoArray.map((item, index) => (
					<div key={index} className='book-info__general-information__block'>
						<span className='book-info__general-information__block__label'>{item.label}</span>{' '}
						<span className='book-info__general-information__block__info'>
							{item.info || 'Chưa có dữ liệu'}
						</span>
					</div>
				))}
			</div>
			<BookReview />
		</div>
	);
};

export default BookInfo;

BookInfo.propTypes = {
	bookInfo: PropTypes.object,
};
