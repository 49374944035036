import '../forget-password.scss';
import ImgForget from 'assets/images/quen-mk 1.png';
import ForgetpasswordFormComponent from './ForgetPasswordFormComponent';
import CreateNewPasswordForm from './CreateNewPasswordForm';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Storage from 'helpers/Storage';
import LoginHeader from 'shared/login-header/LoginHeader';

function ForgetPassWordComponent() {
	const key = useSelector(state => state.forgetPasswordSliceReducer.keyChange);
	const navigate = useNavigate();

	useEffect(() => {
		if (Storage.getAccessToken()) {
			navigate('/');
		}
	}, []);

	return (
		<div className='forget__container'>
			<LoginHeader to='/login' />
			<div className='forget__body--wrapper'>
				<div className='forget__body'>
					<div className='forget__form__img'>
						<img src={ImgForget} alt='img' />
					</div>
					{key ? <CreateNewPasswordForm /> : <ForgetpasswordFormComponent />}
				</div>
			</div>
		</div>
	);
}

export default ForgetPassWordComponent;
