import { useEffect, useRef, useState } from 'react';
import Post from 'shared/post';
import CreatePost from './components/create-post';
import './newfeed.scss';
// import ModalfilterHome from './components/modal-filter-home'; Không xóa
import { GROUP_TYPE, POST_TYPE } from 'constants/index';
import { NotificationError } from 'helpers/Error';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityList } from 'reducers/redux-utils/activity';
import { getPostsByUser, saveDataPrivate } from 'reducers/redux-utils/post';
import LoadingIndicator from 'shared/loading-indicator';
import QuoteCard from 'shared/quote-card';

const NewFeed = () => {
	const { refreshNewfeed } = useSelector(state => state.activity);
	const { postDataPrivate } = useSelector(state => state.post);
	const { userInfo } = useSelector(state => state.auth);

	const [isNewPost, setIsNewPost] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [postList, setPostList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const dispatch = useDispatch();

	const callApiStart = useRef(10);
	const callApiPerPage = useRef(10);

	const onChangeNewPost = () => {
		setIsNewPost(!isNewPost);
	};

	useEffect(async () => {
		callApiStart.current = 10;
		getPostListFirstTime();
	}, [isNewPost, refreshNewfeed]);

	const getPostListFirstTime = async () => {
		setIsLoading(true);
		try {
			const params = {
				start: 0,
				limit: callApiPerPage.current,
				sort: JSON.stringify([{ property: 'createdAt', direction: 'DESC' }]),
			};
			const posts = await dispatch(getActivityList(params)).unwrap();

			if (postDataPrivate) {
				// Nếu vừa đăng bài riêng tư thì xử lí để hiển thị nó lên
				try {
					const data = {
						params: {
							start: 0,
							limit: 1,
							sort: JSON.stringify([{ property: 'createdAt', direction: 'DESC' }]),
						},
						userId: userInfo.id,
					};
					const res = await dispatch(getPostsByUser(data)).unwrap();
					setPostList([res.rows[0], ...posts]);

					// Xóa dữ liệu redux bài riêng tư đi
					dispatch(saveDataPrivate(false));
				} catch (err) {
					NotificationError(err);
				}
			} else {
				setPostList(posts);
			}
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsLoading(false);
		}
	};

	const getPostList = async () => {
		try {
			const params = {
				start: callApiStart.current,
				limit: callApiPerPage.current,
				sort: JSON.stringify([{ property: 'createdAt', direction: 'DESC' }]),
			};
			const posts = await dispatch(getActivityList(params)).unwrap();
			callApiStart.current += callApiPerPage.current;
			const newPosts = posts.filter(item => !postList.some(post => post.id === item.id));
			setPostList(postList.concat(newPosts));
			if (!posts.length) {
				setHasMore(false);
			}
		} catch (err) {
			NotificationError(err);
		}
	};

	const handleUpdatePostArrWhenDeleted = itemMinipostId => {
		const index = postList.findIndex(item => item.id === itemMinipostId);
		const newArr = [...postList];
		newArr.splice(index, 1);
		setPostList(newArr);
	};

	return (
		<div className='newfeed'>
			<div className='newfeed__header'>
				<p>Bảng tin</p>
			</div>
			{<CreatePost onChangeNewPost={onChangeNewPost} />}

			{isLoading ? (
				<LoadingIndicator />
			) : (
				<>
					{postList.length > 0 && (
						<InfiniteScroll
							dataLength={postList.length}
							next={getPostList}
							hasMore={hasMore}
							loader={<LoadingIndicator />}
						>
							{postList.map((item, index) => {
								if (!item.isDeleted) {
									if (item.verb === 'quote') {
										return (
											<div key={index} className='post__container'>
												<QuoteCard data={item} />
											</div>
										);
									} else {
										return (
											<Post
												key={index}
												postInformations={item}
												type={item.verb === 'groupPost' ? GROUP_TYPE : POST_TYPE}
												handleUpdatePostArrWhenDeleted={handleUpdatePostArrWhenDeleted}
											/>
										);
									}
								}
							})}
						</InfiniteScroll>
					)}
				</>
			)}
		</div>
	);
};

export default NewFeed;
