import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { removeMentionAPI } from 'constants/apiURL';
import Request from 'helpers/Request';

export const removeMention = createAsyncThunk('removeMention', async (id, { rejectWithValue }) => {
	try {
		const response = await Request.makePatch(removeMentionAPI(id));
		return response.data;
	} catch (err) {
		const error = JSON.stringify(err.response);
		throw rejectWithValue(error);
	}
});

const friendSlice = createSlice({
	name: 'friends',
	initialState: {
		toggleFollow: '',
	},
	reducers: {
		changeToggleFollows: (state, action) => {
			state.toggle = action.payload;
		},
	},
	extraReducers: {},
});

export const { changeToggleFollows } = friendSlice.actions;
const friends = friendSlice.reducer;
export default friends;
