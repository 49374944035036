import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Request from 'helpers/Request';
import { highlightPostAPI } from 'constants/apiURL';

export const getHightlightPosts = createAsyncThunk('get highlight posts', async (params, { rejectWithValue }) => {
	try {
		const response = await Request.makeGet(highlightPostAPI, params);
		return response.data;
	} catch (err) {
		const error = JSON.parse(err.response);
		throw rejectWithValue(error);
	}
});

const hightlightPostsSlice = createSlice({
	name: 'hightlight-posts',
	initialState: {
		highlightPostReduxData: [],
	},
	reducers: {},
	extraReducers: {
		[getHightlightPosts.fulfilled]: (state, action) => {
			state.highlightPostReduxData = action.payload;
		},
	},
});

const hightlightPostsReducer = hightlightPostsSlice.reducer;
// export const { } = hightlightPostsSlice.actions;
export default hightlightPostsReducer;
