import camera from 'assets/images/camera.png';
import dots from 'assets/images/dots.png';
import pencil from 'assets/images/pencil.png';
import { CloseX, Pencil, QuoteIcon, Upload } from 'components/svg';
import { useCallback, useEffect, useState, useLayoutEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import ConnectButtons from 'shared/connect-buttons';
import { useModal, useVisible } from 'shared/hooks';
import ReadMore from 'shared/read-more';
import UserAvatar from 'shared/user-avatar';
import PersonalInfoForm from './personal-info-form/PersonalInfoForm';
import './personal-info.scss';
import ModalFollowers from './modal-followers';
import ModalWatching from './modal-watching';
import ModalFriend from './modal-friend';
import { useSelector, useDispatch } from 'react-redux';
import { uploadImage } from 'reducers/redux-utils/common';
import _ from 'lodash';
import { editUserInfo } from 'reducers/redux-utils/user';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import backgroundImageDefault from 'assets/images/background-profile.png';
import { updateUserInfo } from 'reducers/redux-utils/auth';
import { useNavigate } from 'react-router-dom';
import { formatNumbers } from 'constants';
import Lightbox from 'react-image-lightbox';
import AvatarEditor from 'react-avatar-editor';
import Button from 'shared/button';
import { NotificationError } from 'helpers/Error';
import defaultAvatar from 'assets/icons/defaultLogoAvatar.svg';

const PersonalInfo = ({ currentUserInfo, setCurrentTab }) => {
	const { ref: settingsRef, isVisible: isSettingsVisible, setIsVisible: setSettingsVisible } = useVisible(false);
	const { modalOpen, setModalOpen, toggleModal } = useModal(false);
	const [modalFriend, setModalFriend] = useState(false);
	const [modalFollower, setModalFollower] = useState(false);
	const [modalFollowing, setModalFollowing] = useState(false);
	const [bgImage, setBgImage] = useState('');
	const [isOpenLightboxAvatar, setIsOpenLightboxAvatar] = useState(false);
	const [isOpenLightboxCover, setIsOpenLightboxCover] = useState(false);

	const [imgAvatarEditor, setImgAvatarEditor] = useState('');
	const [imgCoverEditor, setImgCoverEditor] = useState('');
	const [isOpenModalAvatar, setIsOpenModalAvatar] = useState(false);
	const [isOpenModalCover, setIsOpenModalCover] = useState(false);
	const [borderAvatarEditor, setBoderAvatarEditor] = useState(50);
	const [widthCoverEditor, setWidthCoverEditor] = useState(304);
	const [acceptedFileImage, setAcceptedFileImage] = useState(null);
	const [scale, setScale] = useState(0);

	const { ref: optionAvatarRef, isVisible: showOptionAvatar, setIsVisible: setShowOptionAvatar } = useVisible(false);
	const { ref: optionCoverRef, isVisible: showOptionCover, setIsVisible: setShowOptionCover } = useVisible(false);
	const { userInfo } = useSelector(state => state.auth);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const editorAvatar = useRef(null);
	const editorCover = useRef(null);

	useEffect(() => {
		setModalOpen(false);
	}, [userInfo]);

	useEffect(() => {
		if (currentUserInfo.backgroundImage) {
			setBgImage(currentUserInfo.backgroundImage);
		} else {
			setBgImage(backgroundImageDefault);
		}
	}, [currentUserInfo]);
	useLayoutEffect(() => {
		const handleResize = () => {
			const modalAvatarContentElement = document.querySelector('.avatar-modal .modal-content');
			if (modalAvatarContentElement) {
				const modalContentWidth = modalAvatarContentElement.offsetWidth;
				setBoderAvatarEditor((modalContentWidth - 250) / 2);
			}

			const modalCoverContentElement = document.querySelector('.cover-modal .modal-content');
			if (modalCoverContentElement) {
				const modalContentWidth = modalCoverContentElement.offsetWidth;
				setWidthCoverEditor(modalContentWidth);
			}
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	});

	const handleSettings = () => {
		setSettingsVisible(prev => !prev);
	};

	const handleDrop = useCallback(async (acceptedFile, option) => {
		if (!_.isEmpty(acceptedFile)) {
			setAcceptedFileImage(acceptedFile);
			try {
				if (option === 'backgroundImage') {
					setShowOptionCover(false);
					const url = URL.createObjectURL(acceptedFile[0]);
					setImgCoverEditor(url);
					setIsOpenModalCover(true);
				} else {
					setShowOptionAvatar(false);
					const url = URL.createObjectURL(acceptedFile[0]);
					setImgAvatarEditor(url);
					setIsOpenModalAvatar(true);
				}
			} catch (err) {
				NotificationError(err);
			}
		} else {
			const customId = 'profile-upload-image';
			toast.warning('Chỉ được chọn ảnh PNG, JPG, JPEG và không được quá 3MB', { toastId: customId });
		}
	});

	const onMouseEnterEdit = e => {
		e.target.style.cursor = 'pointer';
	};

	const onClickEditCover = () => {
		setImgCoverEditor(currentUserInfo.originalBackgroundImage || bgImage);
		setAcceptedFileImage(null);
		setIsOpenModalCover(true);
		setShowOptionCover(false);
	};

	const onClickEditAvatar = () => {
		setImgAvatarEditor(currentUserInfo.originalAvatarImage || currentUserInfo.avatarImage);
		setAcceptedFileImage(null);
		setIsOpenModalAvatar(true);
		setShowOptionAvatar(false);
	};

	const closeModalAvatar = () => {
		setIsOpenModalAvatar(false);
	};

	const closeModalCover = () => {
		setIsOpenModalCover(false);
	};

	const handleSave = async () => {
		try {
			if (editorAvatar.current) {
				closeModalAvatar();

				const canvasScaled = editorAvatar.current.getImageScaledToCanvas();
				const croppedImg = await canvasScaled.toDataURL();

				const res = await fetch(croppedImg);
				const arr = await res.arrayBuffer();

				const file = new File([arr], `avatar-${new Date().getTime()}.${getImageType(arr)}`, {
					type: `image/${getImageType(arr)}`,
				});

				if (acceptedFileImage) {
					Promise.all([handleUploadImage([file]), handleUploadImage(acceptedFileImage)]).then(data => {
						const imageCroppedData = data[0];
						const imageFullData = data[1];

						const params = {
							avatarImage: imageCroppedData?.streamPath?.small,
							originalAvatarImage: imageFullData?.streamPath?.default,
						};
						handleCallApiChangeImage(params);
					});
				} else {
					const imageCroppedData = await handleUploadImage([file]);
					const params = {
						avatarImage: imageCroppedData?.streamPath?.small,
					};
					handleCallApiChangeImage(params);
				}
			} else if (editorCover.current) {
				closeModalCover();

				const canvasScaled = editorCover.current.getImageScaledToCanvas();
				const croppedImg = await canvasScaled.toDataURL();

				const res = await fetch(croppedImg);
				const arr = await res.arrayBuffer();
				const file = new File([arr], `cover-${new Date().getTime()}.${getImageType(arr)}`, {
					type: `image/${getImageType(arr)}`,
				});

				if (acceptedFileImage) {
					Promise.all([handleUploadImage([file]), handleUploadImage(acceptedFileImage)]).then(data => {
						const imageCroppedData = data[0];
						const imageFullData = data[1];

						const params = {
							backgroundImage: imageCroppedData?.streamPath?.default,
							originalBackgroundImage: imageFullData?.streamPath?.default,
						};
						handleCallApiChangeImage(params);
					});
				} else {
					const imageCroppedData = await handleUploadImage([file]);
					const params = {
						backgroundImage: imageCroppedData?.streamPath?.default,
					};
					handleCallApiChangeImage(params);
				}
			}
		} catch (error) {
			toast.error('Có lỗi xảy ra');
		}
	};

	function getImageType(arrayBuffer) {
		let type = 'jpg';
		const dv = new DataView(arrayBuffer, 0, 5);
		const nume1 = dv.getUint8(0, true);
		const nume2 = dv.getUint8(1, true);
		const hex = nume1.toString(16) + nume2.toString(16);

		switch (hex) {
			case '8950':
				type = 'png';
				break;
			case '4749':
				type = 'gif';
				break;
			case '424d':
				type = 'bmp';
				break;
			case 'ffd8':
				type = 'jpeg';
				break;
			default:
				break;
		}
		return type;
	}

	const handleUploadImage = async file => {
		try {
			const res = await dispatch(uploadImage(file)).unwrap();
			return res;
		} catch (error) {
			NotificationError(error);
		}
	};

	const handleCallApiChangeImage = async params => {
		try {
			const changeUserImage = await dispatch(editUserInfo(params)).unwrap();
			dispatch(updateUserInfo(changeUserImage));
			if (!_.isEmpty(changeUserImage)) {
				const customId = 'custom-id-PersonalInfo-handleDrop-success';
				toast.success('Cập nhật ảnh thành công', { toastId: customId, autoClose: 1500 });
			}
		} catch (error) {
			if (error.statusCode === 413) {
				const customId = 'custom-id-PersonalInfo-handleDrop-warning';
				toast.warning('Không cập nhật được ảnh quá 10Mb', { toastId: customId });
			} else {
				const customId = 'custom-id-PersonalInfo-handleDrop-error';
				toast.error('Cập nhật ảnh thất bại', { toastId: customId });
			}
		}
	};
	const decreaseScale = () => {
		if (scale >= 10) {
			setScale(prev => Number(prev) - 10);
		} else {
			setScale(0);
		}
	};

	const increaseScale = () => {
		if (scale <= 90) {
			setScale(prev => Number(prev) + 10);
		} else {
			setScale(100);
		}
	};

	return (
		<div className='personal-info'>
			<div className='personal-info__wallpaper'>
				<img
					src={bgImage}
					alt='background-image'
					onError={() => setBgImage(backgroundImageDefault)}
					onClick={() => setIsOpenLightboxCover(!isOpenLightboxAvatar)}
					role='button'
				/>
				{isOpenLightboxCover && (
					<Lightbox
						mainSrc={currentUserInfo.originalBackgroundImage || bgImage}
						onCloseRequest={() => setIsOpenLightboxCover(false)}
					/>
				)}
				<div className='edit-wallpaper'>
					{currentUserInfo.id === userInfo.id && (
						<>
							<button
								className='edit-wallpaper__btn'
								onClick={() => setShowOptionCover(!showOptionCover)}
							>
								<img src={camera} alt='camera' />
								<span>Chỉnh sửa ảnh bìa</span>
							</button>
							<ul
								className={`header__option-info cover-edit ${showOptionCover && 'show'}`}
								ref={optionCoverRef}
							>
								<li>
									<Dropzone
										onDrop={acceptedFile => handleDrop(acceptedFile, 'backgroundImage')}
										multiple={false}
										accept={['.png', '.jpeg', '.jpg']}
										maxSize={3000000}
									>
										{({ getRootProps, getInputProps }) => (
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												<Upload />
												<span>&nbsp;Tải ảnh lên</span>
											</div>
										)}
									</Dropzone>
								</li>
								<li onClick={onClickEditCover}>
									<Pencil />
									&nbsp;Đặt lại vị trí
								</li>
							</ul>
						</>
					)}
				</div>
				<Modal
					className='personal-info__edit-image-modal cover-modal'
					show={isOpenModalCover}
					onHide={closeModalCover}
					centered
					size='lg'
				>
					<Modal.Header closeButton>
						<span>Cập nhật ảnh bìa</span>
					</Modal.Header>
					<Modal.Body>
						<div className='text-center'>
							<AvatarEditor
								ref={editorCover}
								width={widthCoverEditor}
								height={(widthCoverEditor * 35) / 92}
								border={0}
								image={imgCoverEditor}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button isOutline onClick={closeModalCover} className='me-3'>
							Hủy bỏ
						</Button>
						<Button onClick={handleSave}>Lưu</Button>
					</Modal.Footer>
				</Modal>
			</div>

			<div className='personal-info__detail'>
				<div className='personal-info__detail__avatar-and-name'>
					<div className='personal-info__detail__avatar'>
						<UserAvatar
							size='xl'
							source={currentUserInfo.avatarImage}
							className='personal-info__detail__avatar__user'
							handleClick={() => setIsOpenLightboxAvatar(true)}
						/>
						{currentUserInfo.id === userInfo.id && (
							<div className='edit-avatar'>
								<button
									className='edit-avatar__btn'
									onClick={() => setShowOptionAvatar(!showOptionAvatar)}
								>
									<img src={camera} alt='camera' />
								</button>

								<ul
									className={`header__option-info avatar-edit ${showOptionAvatar && 'show'}`}
									ref={optionAvatarRef}
								>
									<li>
										<Dropzone
											onDrop={acceptedFile => handleDrop(acceptedFile, 'avatarImage')}
											multiple={false}
											accept={['.png', '.jpeg', '.jpg']}
										>
											{({ getRootProps, getInputProps }) => (
												<div {...getRootProps()}>
													<input {...getInputProps()} />
													<Upload />
													<span>&nbsp;Tải ảnh lên</span>
												</div>
											)}
										</Dropzone>
									</li>
									<li onClick={onClickEditAvatar}>
										<Pencil />
										&nbsp;Chỉnh sửa
									</li>
								</ul>
							</div>
						)}
						<Modal
							className='personal-info__edit-image-modal avatar-modal'
							show={isOpenModalAvatar}
							onHide={closeModalAvatar}
							centered
						>
							<Modal.Header closeButton>
								<span>Cập nhật ảnh đại diện</span>
							</Modal.Header>
							<Modal.Body>
								<div className='text-center'>
									<AvatarEditor
										ref={editorAvatar}
										width={250}
										height={250}
										border={[borderAvatarEditor, 50]}
										image={imgAvatarEditor}
										borderRadius={150}
										scale={1 + scale * 0.05}
										color={[255, 255, 255, 0.4]}
									/>
								</div>
								<div className='py-3 px-5 d-flex gap-3'>
									<button className='read-challenge__input__button-element' onClick={decreaseScale}>
										&#8722;
									</button>
									<input
										type='range'
										className='w-75'
										value={scale}
										onChange={e => setScale(e.target.value)}
									/>
									<button className='read-challenge__input__button-element' onClick={increaseScale}>
										&#43;
									</button>
								</div>
							</Modal.Body>
							<Modal.Footer>
								<Button isOutline onClick={closeModalAvatar} className='me-3'>
									Hủy bỏ
								</Button>
								<Button onClick={handleSave}>Lưu</Button>
							</Modal.Footer>
						</Modal>
						{isOpenLightboxAvatar && (
							<Lightbox
								mainSrc={
									currentUserInfo.originalAvatarImage || currentUserInfo.avatarImage || defaultAvatar
								}
								onCloseRequest={() => setIsOpenLightboxAvatar(false)}
							/>
						)}
					</div>
					<div className='personal-info__detail__name'>
						<div className='personal-info__username'>
							<h4
								title={
									currentUserInfo.lastName
										? currentUserInfo.fullName
											? currentUserInfo.fullName
											: currentUserInfo.firstName + ' ' + currentUserInfo.lastName
										: currentUserInfo.firstName
								}
							>
								{currentUserInfo.lastName
										? currentUserInfo.fullName
											? currentUserInfo.fullName
											: currentUserInfo.firstName + ' ' + currentUserInfo.lastName
										: currentUserInfo.firstName}
							</h4>
							{currentUserInfo.id === userInfo.id && (
								<div className='edit-name' onMouseEnter={onMouseEnterEdit} onClick={toggleModal}>
									<img className='edit-name__pencil' src={pencil} alt='pencil' />
									<button>Chỉnh sửa tên</button>
								</div>
							)}
							<div ref={settingsRef} className='setting'>
								<button className='setting-btn' onClick={handleSettings}>
									<img src={dots} alt='setting' />
								</button>
								{isSettingsVisible && (
									<ul className='setting-list'>
										{currentUserInfo.id === userInfo.id && (
											<li
												className='setting-item'
												onClick={() => {
													handleSettings();
													setModalOpen(true);
												}}
											>
												<Pencil />
												<span className='setting-item__content'>
													Chỉnh sửa thông tin cá nhân
												</span>
											</li>
										)}

										<li
											className='setting-item'
											onClick={() => {
												handleSettings();
												navigate(`/quotes/${currentUserInfo.id}`);
											}}
										>
											<QuoteIcon />
											<span className='setting-item__content'>Quotes</span>
										</li>
									</ul>
								)}
							</div>
						</div>
						<div className='personal-info__email'>{currentUserInfo.email}</div>
					</div>
				</div>

				<div className='personal-info__detail__connect-buttons-and-introduction'>
					<div className={currentUserInfo.id === userInfo.id ? 'personal-info-none' : ''}>
						<ConnectButtons item={currentUserInfo} />
					</div>
					<div className='personal-info__detail__introduction'>
						<ul className='personal-info__list'>
							<li onClick={() => setCurrentTab('post')} className='personal-info__item'>
								<span className='number'>
									{currentUserInfo.posts > 0 ? formatNumbers(currentUserInfo.posts) : 0}
								</span>
								<span>Bài viết</span>
							</li>
							<li onClick={() => setModalFollower(true)} className='personal-info__item'>
								<span className='number'>
									{currentUserInfo.follower > 0 ? formatNumbers(currentUserInfo.follower) : 0}
								</span>
								<span>Người theo dõi</span>
							</li>

							{modalFollower && (
								<ModalFollowers
									setModalFollower={setModalFollower}
									modalFollower={modalFollower}
									userInfoDetail={currentUserInfo}
								/>
							)}
							<li onClick={() => setModalFollowing(true)} className='personal-info__item'>
								<span className='number'>
									{currentUserInfo.following > 0 ? formatNumbers(currentUserInfo.following) : 0}
								</span>
								<span>Đang theo dõi</span>
							</li>
							{modalFollowing && (
								<ModalWatching
									setModalFollowing={setModalFollowing}
									modalFollowing={modalFollowing}
									userInfoDetail={currentUserInfo}
								/>
							)}
							<li onClick={() => setModalFriend(true)} className='personal-info__item'>
								<span className='number'>
									{currentUserInfo.friends > 0 ? formatNumbers(currentUserInfo.friends) : 0}
								</span>
								<span>
									Bạn bè
									{currentUserInfo.id !== userInfo.id ? (
										<span>
											{' '}
											(
											{currentUserInfo.mutualFriends > 0
												? formatNumbers(currentUserInfo.mutualFriends)
												: 0}{' '}
											bạn chung)
										</span>
									) : null}
								</span>
							</li>
							{modalFriend && (
								<ModalFriend
									setModalFriend={setModalFriend}
									modalFriend={modalFriend}
									userInfoDetail={currentUserInfo}
								/>
							)}
						</ul>
						{currentUserInfo.descriptions && (
							<ReadMore text={currentUserInfo.descriptions} viewLessHeight={70} />
						)}
					</div>
				</div>
			</div>

			<Modal className='personal-info__modal' show={modalOpen} onHide={toggleModal}>
				<Modal.Header>
					<h4 className='modal-title'>Chỉnh sửa thông tin cá nhân</h4>
					<button className='close-btn' onClick={toggleModal}>
						<CloseX />
					</button>
				</Modal.Header>
				<Modal.Body>
					<PersonalInfoForm userData={currentUserInfo} toggleModal={toggleModal} />
				</Modal.Body>
			</Modal>
		</div>
	);
};

PersonalInfo.propTypes = {
	currentUserInfo: PropTypes.object,
	setCurrentTab: PropTypes.func,
};

export default PersonalInfo;
