import { BackArrow } from 'components/svg';
import PropTypes from 'prop-types';
import './back-button.scss';
import { useNavigate } from 'react-router-dom';

const BackButton = ({ destination }) => {
	const navigate = useNavigate();

	const backFnc = () => {
		const type = typeof destination;
		if (type === 'function') {
			destination();
		} else {
			navigate(destination);
		}
	};

	return (
		<button className='back-btn' onClick={backFnc}>
			<BackArrow />
		</button>
	);
};

BackButton.defaultProps = {
	destination: -1,
};

BackButton.propTypes = {
	destination: PropTypes.any,
};

export default BackButton;
