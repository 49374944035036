import './eventbar.scss';
// import eventBarImageSample from 'assets/images/event-bar-image-sample.jpg';
import { lazy, Suspense } from 'react';
import LoadingIndicator from 'shared/loading-indicator';
import HighlightPosts from 'shared/hightlight-posts/HighlightPosts';
// import welcomeImage from 'assets/images/welcome.png';
import welcomeBibooImage from 'assets/images/event-bar-image-biboo.jpg';
const SidebarRank = lazy(() => import('./sidebarRank'));

const EventBar = () => {
	return (
		<Suspense fallback={<LoadingIndicator />}>
			<div className='event-and-rank-bar'>
				{/* <div className='event-and-rank-bar__block'>
					<h4 className='event-and-rank-bar__block__title'>Sự kiện</h4>
					<div className='event-and-rank-bar__content'>
						<div className='event-bar__name'>World Books Day</div>
						<img src={eventBarImageSample} alt='' />
						<button className='event-bar__join-event'>Tham gia sự kiện</button>
					</div>
				</div> */}
				<SidebarRank />
				{/* <div
					onClick={() => window.open('https://forms.gle/HW9Q3erDGYUucsLC8')}
					className='event-and-rank-bar__welcome'
				>
					<img src={welcomeImage} alt='welcome' className='event-and-rank-bar__welcome__image' />
				</div> */}
				<div
					onClick={() => window.open('https://biboo.com.vn/')}
					className='event-and-rank-bar__welcome'
				>
					<img src={welcomeBibooImage} alt='welcome' className='event-and-rank-bar__welcome__image' />
				</div>
				<HighlightPosts />
			</div>
		</Suspense>
	);
};

export default EventBar;
