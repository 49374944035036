import './SeeMoreComments.scss';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { NotificationError } from 'helpers/Error';
import { useDispatch } from 'react-redux';
import { getQuoteComments } from 'reducers/redux-utils/quote';
import { getGroupPostComments, getMiniPostComments } from 'reducers/redux-utils/post';
import { getListCommentsReview } from 'reducers/redux-utils/book';
import { useState } from 'react';
import LoadingIndicator from 'shared/loading-indicator';
import { useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const SeeMoreComments = ({
	data = {},
	setData = () => {},
	haveNotClickedSeeMoreOnce,
	setHaveNotClickedSeeMoreOnce = () => {},
	isInDetail = false,
	postType = '',
}) => {
	const dispatch = useDispatch();

	const callApiStart = useRef(0);
	const callApiPerPage = useRef(10);

	const [isLoading, setIsLoading] = useState(false);
	const [show, setShow] = useState(false);

	const { dataDeleteCmt } = useSelector(state => state.comment);

	useEffect(() => {
		// Kiểm tra xem nếu bài viết chưa hiển thị đủ comment thì hiện nút Xem thêm
		checkShow();

		// Nếu ở trong màn detail thì bắt đầu gọi từ số comment hiển thị là data?.usersComments?.length
		if (isInDetail && haveNotClickedSeeMoreOnce) {
			callApiStart.current = data?.usersComments?.length || 0;
		}
		// Còn ở ngoài (!isInDetail) thì bắt đầu gọi từ 0
	}, [data]);

	useEffect(() => {
		if (!_.isEmpty(dataDeleteCmt) && callApiStart.current > 0) {
			callApiStart.current = callApiStart.current - 1;
		}
	}, [dataDeleteCmt]);

	const checkShow = () => {
		if (!isInDetail && haveNotClickedSeeMoreOnce) {
			// Nếu không ở trong màn detail và chưa bấm nút Xem thêm thì chỉ hiển thị 1 comment cha,
			// cho nên chỉ cần kiểm tra là nếu data.usersComments.length > 1 thì cho hiện nút Xem thêm.
			data.comment > 1 ? setShow(true) : setShow(false);
		} else {
			// Các trường hợp còn lại, ta lấy tổng số comment cha đã hiện ra và số comment con của chúng,
			// so sánh với data.comment (data.comment là tổng số cmt cha và số cmt con), nếu nhỏ hơn thì cho
			// hiện nút Xem thêm, không thì ẩn đi.
			let totalShowComments = data.usersComments?.length || 0;
			for (let i = 0; i < data.usersComments?.length; i++) {
				if (Array.isArray(data.usersComments[i].reply) && data.usersComments[i].reply.length) {
					totalShowComments += data.usersComments[i].reply.length;
				}
			}
			totalShowComments < data.comment ? setShow(true) : setShow(false);
		}
	};

	const onClickSeeMore = async () => {
		setIsLoading(true);
		let sentData = {};
		let res = {};

		const params = {
			start: callApiStart.current,
			limit: callApiPerPage.current,
			sort: JSON.stringify([{ property: 'createdAt', direction: 'DESC' }]),
		};

		try {
			if (postType === 'quote') {
				sentData = {
					quoteId: data.id,
					params: params,
				};
				res = await dispatch(getQuoteComments(sentData)).unwrap();
			} else if (postType === 'post') {
				sentData = {
					postId: data.minipostId || data.id,
					params: params,
				};
				res = await dispatch(getMiniPostComments(sentData)).unwrap();
			} else if (postType === 'group') {
				sentData = {
					postId: data.groupPostId || data.id,
					params: params,
				};
				res = await dispatch(getGroupPostComments(sentData)).unwrap();
			} else if (postType === 'review') {
				sentData = {
					reviewId: data.id,
					params: params,
				};
				res = await dispatch(getListCommentsReview(sentData)).unwrap();
			}
			if (res.rows?.length > 0) {
				handleAddMoreComments(data, res.rows);
			}
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsLoading(false);
		}
	};

	const handleAddMoreComments = (paramData, paramRows) => {
		const cloneObj = { ...paramData };
		if (haveNotClickedSeeMoreOnce) {
			setHaveNotClickedSeeMoreOnce(false);
			if (!isInDetail) {
				cloneObj.usersComments = [];
			}
		}
		// Đảo ngược cả các comment reply nữa
		for (let i = 0; i < paramRows.length; i++) {
			if (paramRows[i].reply.length > 0) {
				const commentsChildReverse = [...paramRows[i].reply];
				commentsChildReverse.reverse();
				const newCloneObj = { ...paramRows[i] };
				newCloneObj.reply = commentsChildReverse;
				paramRows[i] = newCloneObj;
			}
		}

		paramRows.forEach(item => cloneObj.usersComments.unshift(item));
		setData(cloneObj);
		callApiStart.current += callApiPerPage.current;
	};

	return (
		<>
			{show && (
				<>
					{isLoading ? (
						<div className='loading-more-comments'>
							<LoadingIndicator />
						</div>
					) : (
						<div className='see-more-comment'>
							<span className='see-more-comment__button' onClick={onClickSeeMore}>
								Xem thêm
							</span>
						</div>
					)}
				</>
			)}
		</>
	);
};

SeeMoreComments.propTypes = {
	data: PropTypes.object,
	setData: PropTypes.func,
	haveNotClickedSeeMoreOnce: PropTypes.bool,
	setHaveNotClickedSeeMoreOnce: PropTypes.func,
	isInDetail: PropTypes.bool,
	postType: PropTypes.string,
};

export default SeeMoreComments;
