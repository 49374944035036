import PropTypes from 'prop-types';
import { Suspense, lazy, useEffect, useState } from 'react';
import defaultAvatar from 'assets/icons/defaultLogoAvatar.svg';
const Post = lazy(() => import('shared/post'));
import './search-group.scss';
import _ from 'lodash';
import ResultNotFound from 'pages/result/component/result-not-found';
import { GROUP_TYPE } from 'constants';
import { Link } from 'react-router-dom';
import ConnectButtonsGroup from './ConnectButtonsGroup';
import { useSelector } from 'react-redux';

function SearchLayout({ dataGroup }) {
	const { userInfo } = useSelector(state => state.auth);
	const [postList, setPostList] = useState([]);

	useEffect(() => {
		// Gộp 2 array (bài có nội dung tìm kiếm và bài của người được tìm kiếm)
		const array = dataGroup?.postData.concat(dataGroup?.searchUserPost);
		// Xóa các bài viết trùng id, tạo ra array mới để render.
		const uniqueArray = array.filter((value, index, self) => {
			return index === self.findIndex(obj => obj.id === value.id);
		});
		const arrTemp = uniqueArray.map(item => {
			if (item?.metaData?.progress) {
				item.book.progress = item?.metaData?.progress;
			}
			return item;
		});
		setPostList(arrTemp);
	}, [dataGroup]);

	return (
		<>
			{_.isEmpty(dataGroup?.usersData) && _.isEmpty(dataGroup?.postData) ? (
				<div className='search-group__not-found'>
					<ResultNotFound />
				</div>
			) : (
				<div className='search-group__container'>
					{!_.isEmpty(dataGroup?.usersData) && (
						<div className='searh-group__member'>
							{dataGroup?.usersData?.map((item, index) => {
								return (
									<div className='member-item' key={index}>
										<div className='member-item__info'>
											<Link to={`/profile/${item.id}`}>
												<img
													src={item.avatarImage ? item.avatarImage : defaultAvatar}
													onError={e => e.target.setAttribute('src', defaultAvatar)}
													alt='image'
												/>
											</Link>
											<div className='member-item__text'>
												<Link to={`/profile/${item.id}`}>
													<span>
														{item?.firstName + ' ' + item?.lastName || item.fullName}
													</span>
												</Link>
												{item.mutualFriend && item.id !== userInfo.id ? (
													<p>
														{item.mutualFriend < 10
															? `0${item.mutualFriend} `
															: `${item.mutualFriend} `}
														bạn chung
													</p>
												) : (
													''
												)}
											</div>
										</div>
										{item.id !== userInfo.id && <ConnectButtonsGroup item={item} />}
									</div>
								);
							})}
						</div>
					)}
					<div>
						{postList.map((item, index) => {
							return (
								<Suspense key={index} fallback={<></>}>
									<Post postInformations={item} type={GROUP_TYPE} />
								</Suspense>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
}

SearchLayout.propTypes = {
	dataGroup: PropTypes.array,
};

export default SearchLayout;
