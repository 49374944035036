import PropTypes from 'prop-types';
import { useLayoutEffect, useRef, useState } from 'react';
import shareImg from 'assets/images/alert-circle-fill.png';
import facebookImg from 'assets/images/facebook.png';
import StatusButton from 'components/status-button';
import { AppStore, CircleCheckFullFill, GooglePlay } from 'components/svg';
import BookThumbnail from 'shared/book-thumbnail';
import ReactRating from 'shared/react-rating';
import ReadMore from 'shared/read-more';
import './book-intro.scss';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FacebookShareButton } from 'react-share';
import Storage from 'helpers/Storage';
import { checkUserLogin } from 'reducers/redux-utils/auth';
import { handleSaveConfirmUserData } from 'reducers/redux-utils/book';
import { Crown, ForwardGroup } from 'components/svg';
import { useVisible } from 'shared/hooks';
import LogoShoppe from 'assets/images/logoShoppe.png';
import LogoBiboo from 'assets/images/logoBiboo.jpg';

const BookIntro = ({ bookInfo, listRatingStar, topBookRank = 0 }) => {
	const userInfo = useSelector(state => state.auth.userInfo);
	const reviewsNumber = useSelector(state => state.book.currentBookReviewsNumber);
	const { ref: showRef, isVisible: isShow, setIsVisible: setIsShow } = useVisible(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const urlShare = useRef(`${window.location.hostname + window.location.pathname}`);

	const [viewLessHeight, setViewLessHeight] = useState(0);
	const [size, setSize] = useState('lg');

	useLayoutEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			if (width >= 768) {
				setSize('lg');

				const bookHeight = document.querySelector('.book-intro .book-thumbnail').offsetHeight;
				const takenHeight = document.querySelector('.book-intro__content__infomations__block-up').offsetHeight;
				setViewLessHeight(bookHeight - takenHeight);
			} else {
				setViewLessHeight(150);
				if (width >= 500) {
					setSize('md');
				} else {
					setSize('sm');
				}
			}
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	});

	const handleClick = () => {
		if (location.pathname !== '/' || location.pathname !== '/home') {
			navigate('/');
		}
	};

	const handleShareFaceBook = () => {
		if (Storage.getAccessToken()) {
			dispatch(checkUserLogin(false));
		} else {
			dispatch(checkUserLogin(true));
		}
	};

	const onClickUserName = (userData, role) => {
		if (!_.isEmpty(userInfo)) {
			// Nếu tác giả đã được xác thực thì sang màn cá nhân, không thì sang màn xác thực
			if (userData.verify) {
				navigate(`/profile/${userData.authorId || userData.translatorId}`);
			} else {
				dispatch(
					handleSaveConfirmUserData({
						userId: userData.authorId ? userData.authorId : userData.translatorId,
						userName: userData.authorName ? userData.authorName : userData.translatorName,
						role: role,
					})
				);
				navigate(`/confirm-my-book/${bookInfo.id}/`);
			}
		} else {
			dispatch(checkUserLogin(true));
		}
	};

	const handleDirect = path => {
		window.open(path, '_blank');
		// setIsShow(false)
	};

	const renderButtonMedia = () => {
		return (
			<>
				{/* <button
					className='cursor-pointer item__link d-flex gap-3 p-3 align-items-center bg-light button-link book-intro__buy'
					style={{ borderRadius: '12px', height: '56px' }}
					onClick={() => handleDirect(bookInfo.externalProductLink?.biboo)}
					disabled={bookInfo.externalProductLink === null}
				>
					<img src={LogoBiboo} alt='' />
					<span>Sách điện tử Biboo</span>
				</button> */}
				<div onClick={handleShareFaceBook} className='book-intro__action button-link'>
					<FacebookShareButton url={urlShare.current}>
						<div className='book-intro__share'>
							<img src={shareImg} alt='share' />
							<span className='book-intro__share__text'>Chia sẻ</span>
						</div>
						<div className='book-intro__share'>
							<img src={facebookImg} alt='facebook' />
						</div>
					</FacebookShareButton>
				</div>
				<div className='position-relative' ref={showRef}>
					<div
						className='d-flex align-items-center gap-4 p-3 bg-light cursor-pointer button-link'
						style={{ borderRadius: '12px' }}
						onClick={() => setIsShow(!isShow)}
					>
						<div className='book-intro__share'>
							<span className='book-intro__share__text'>Mua sách ngay</span>
						</div>
						<div className='book-intro__share'>
							<ForwardGroup />
						</div>
					</div>
					{isShow && (
						<div className='position-absolute bg-light left-0 popup-link'>
							<div>
								<button
									className='cursor-pointer item__link d-flex gap-3 align-items-center'
									disabled={!bookInfo.externalProductLink.shoppe}
									onClick={() => handleDirect(bookInfo.externalProductLink.shoppe)}
								>
									<img src={LogoShoppe} alt='' style={{width: "32px", height: "32px"}} />
									Shoppe
								</button>
								<button
									className='cursor-pointer item__link d-flex gap-3 align-items-center'
									disabled={!bookInfo.externalProductLink.biboo}
									onClick={() => handleDirect(bookInfo.externalProductLink.biboo)}
								>
									<img src={LogoBiboo} alt='' />
									Sách điện tử Biboo
								</button>
								<button
									className='cursor-pointer item__link d-flex gap-3 align-items-center'
									disabled={!bookInfo.externalProductLink.google_play}
									onClick={() => handleDirect(bookInfo.externalProductLink.google_play)}
								>
									<GooglePlay/>
									Google Play
								</button>
								<button
									className='cursor-pointer item__link d-flex gap-3 align-items-center'
									disabled={!bookInfo.externalProductLink.app_store}
									onClick={() => handleDirect(bookInfo.externalProductLink.app_store)}
								>
									<AppStore />
									App Store
								</button>
							</div>
						</div>
					)}
				</div>
			</>
		);
	};

	return (
		<>
			{size !== 'lg' && (
				<h1 className='book-intro__name' title={bookInfo.name}>
					{bookInfo.name}
				</h1>
			)}
			<div className='book-intro'>
				<div className='book-intro__image'>
					<div>
						<BookThumbnail {...bookInfo} size={size} />
					</div>
					{size !== 'lg' ? (
						<div className='book-intro__content'>
							<div className='book-intro__content__infomations'>
								<div className='book-intro__content__infomations__block-up'>
									<div className='book-intro__author-and-translator'>
										<div className='book-intro__author'>
											{Array.isArray(bookInfo.authors) && bookInfo.authors.length > 0 ? (
												<>
													Bởi
													{bookInfo.authors.map((author, index) => (
														<span key={index} className='book-intro__author__name'>
															<span
																className='verified'
																onClick={() => onClickUserName(author, 'author')}
															>
																{author.authorName}
															</span>
															{author.verify && (
																<CircleCheckFullFill className='book-intro__check' />
															)}
															{index + 1 < bookInfo.authors.length && ', '}
														</span>
													))}
												</>
											) : (
												'Chưa cập nhật tác giả'
											)}
										</div>

										{Array.isArray(bookInfo.translators) && bookInfo.translators.length > 0 && (
											<div className='book-intro__author'>
												Dịch giả
												{bookInfo.translators.map((translators, index) => (
													<span key={index} className='book-intro__author__name'>
														<span
															className='verified'
															onClick={() => onClickUserName(translators, 'translator')}
														>
															{translators.translatorName}
														</span>
														{translators.verify && (
															<CircleCheckFullFill className='book-intro__check' />
														)}
														{index + 1 < bookInfo.translators.length && ', '}
													</span>
												))}
											</div>
										)}
									</div>

									{topBookRank > 0 && (
										<div className='book-intro__is-in-top'>
											<div className='book-intro__is-in-top__crown'>
												<Crown />
											</div>
											Top {topBookRank} cuốn sách tốt nhất theo tuần
										</div>
									)}

									<div className='book-intro__stars'>
										<ReactRating readonly={true} initialRating={listRatingStar?.avg} />
										{listRatingStar?.count > 0 && <span>({listRatingStar.count})</span>}
										<span>
											({reviewsNumber} review{reviewsNumber > 1 ? 's' : ''})
										</span>
									</div>

									<div className='d-flex gap-3 flex-wrap'>
										<StatusButton
											className='book-intro__btn'
											handleClick={handleClick}
											bookData={bookInfo}
											bookStatus={bookInfo.status}
										/>
										{renderButtonMedia()}
									</div>
								</div>
							</div>
						</div>
					) : (
						<StatusButton
							className='book-intro__btn'
							handleClick={handleClick}
							bookData={bookInfo}
							bookStatus={bookInfo.status}
						/>
					)}
				</div>
				<div className='book-intro__content'>
					<div className='book-intro__content__infomations'>
						{size === 'lg' && (
							<div className='book-intro__content__infomations__block-up'>
								<h1 className='book-intro__name' title={bookInfo.name}>
									{bookInfo.name}
								</h1>

								<div className='book-intro__author-and-translator'>
									<div className='book-intro__author'>
										{Array.isArray(bookInfo.authors) && bookInfo.authors.length > 0 ? (
											<>
												Bởi
												{bookInfo.authors.map((author, index) => (
													<span key={index} className='book-intro__author__name'>
														<span
															className='verified'
															onClick={() => onClickUserName(author, 'author')}
														>
															{author.authorName}
														</span>
														{author.verify && (
															<CircleCheckFullFill className='book-intro__check' />
														)}
														{index + 1 < bookInfo.authors.length && ', '}
													</span>
												))}
											</>
										) : (
											'Chưa cập nhật tác giả'
										)}
									</div>

									{Array.isArray(bookInfo.translators) && bookInfo.translators.length > 0 && (
										<div className='book-intro__author'>
											Dịch giả
											{bookInfo.translators.map((translators, index) => (
												<span key={index} className='book-intro__author__name'>
													<span
														className='verified'
														onClick={() => onClickUserName(translators, 'translator')}
													>
														{translators.translatorName}
													</span>
													{translators.verify && (
														<CircleCheckFullFill className='book-intro__check' />
													)}
													{index + 1 < bookInfo.translators.length && ', '}
												</span>
											))}
										</div>
									)}
								</div>

								{topBookRank > 0 && (
									<div className='book-intro__is-in-top'>
										<div className='book-intro__is-in-top__crown'>
											<Crown />
										</div>
										Top {topBookRank} cuốn sách tốt nhất theo tuần
									</div>
								)}

								<div className='book-intro__stars'>
									<ReactRating readonly={true} initialRating={listRatingStar?.avg} />
									{listRatingStar?.count > 0 && <span>({listRatingStar.count})</span>}
									<span>
										({reviewsNumber} review{reviewsNumber > 1 ? 's' : ''})
									</span>
								</div>
							</div>
						)}

						<div className='book-intro__description'>
							<ReadMore text={bookInfo.description || 'Chưa cập nhật'} viewLessHeight={viewLessHeight} />
						</div>
					</div>

					{size === 'lg' && <div className='d-flex align-items-center gap-4'>{renderButtonMedia()}</div>}
				</div>
			</div>
		</>
	);
};

export default BookIntro;

BookIntro.propTypes = {
	bookInfo: PropTypes.object,
	listRatingStar: PropTypes.object,
	topBookRank: PropTypes.number,
};
