import Logo from 'assets/images/Logo 2.png';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './loginHeader.scss';

export default function LoginHeader({ to }) {
	return (
		<div className='login__header'>
			<Link to={to}>
				<img src={Logo} alt='logo' />
			</Link>
		</div>
	);
}

LoginHeader.defaultProps = {
	to: '/',
};

LoginHeader.propTypes = {
	to: PropTypes.string,
};
